<!-- 
	This is the dashboard page, it uses the dashboard layout in: 
	"./layouts/Dashboard.vue" .
 -->

 <template>
	<div class="pt-20 px-10">
		
		<a-card class="px-10" style="margin-top: 0px;">

			<a-row :gutter="24">

				<a-col :span="24"  :md="24" :lg="24" :xl="24" class="mt-20 mb-24 px-20">
					<h4>Edit Quiz Questions</h4>
				</a-col>

			</a-row>

			<a-row :gutter="24" v-if="!loadingDetails">

				<a-col class="mb-24" style="height: 100%;" :span="24" :md="12" :lg="12" :xl="12">
					<a-card class="px-5 h-100">
						<a-form-model
							class="question-form"
							@submit="handleSubmit"
							:hideRequiredMark="true">

							<a-form-item class="mb-20" label="Question" :colon="false">
								<quill-editor
									ref="questionEditor"
									v-model="questionContent"
									:options="editorOption"
									@change="handleQuestionChange($event)"
									required
								/>
							</a-form-item>
							
							<a-form-item class="mb-10 mt-20" label="Position" :colon="false">
								<a-input 
									type="number"
									v-decorator="[
										'position',
										{ rules: [{ required: true, message: 'Please input your question position!' }] },
									]" 
									placeholder="Position" 
									v-model="question.position"/>
							</a-form-item>

							<a-form-item class="text-right">
								<a-button type="primary" id="btn" :loading="loadingData" html-type="submit" class="login-form-button text-white">
									Update
								</a-button>
							</a-form-item>

						</a-form-model>
					</a-card>
				</a-col>


				<a-col class="mb-24" style="height: 100%;" :span="24" :md="12" :lg="12" :xl="12">
					<a-card class=" px-5 h-100">
						<a-row class="mb-10">
							<a-col :span="12">
								<h4>Choices</h4> 
							</a-col>

							<a-col :span="12" class="text-right">
								<a-button id="btn" type="primary" class="btn btn-silabu" @click="toggleChoiceDialog">
									<a-icon type="plus" theme="outlined" /> &nbsp;&nbsp; Add Choice
								</a-button>
							</a-col>
						</a-row>

						<a-row>
							<a-col :span="24" :md="24" :lg="24" v-for="(opt, index) in question.choices" :key="index">
								<span style="display: inline !important;"><span style="">{{ getLabel(index) }}.</span> <span  id="deltaValue" class="col-oder-2" v-html="quillToHtml(opt.content)"></span>
								&nbsp;&nbsp;<a-icon type="check" class="text-success" theme="outlined" v-if="opt.correct" /> </span>
								<a-button type="text" :bordered="false" id="editBtn" class="text-silabu ml-15" @click="handleEditAnswer(index, opt)" danger><a-icon type="form" size="medium" theme="outlined" /></a-button>
								<a-button type="text" id="deleteBtn" class="text-danger ml-5" @click="removeChoice(index)" danger><a-icon type="delete" size="medium" theme="outlined" /></a-button>
							</a-col>
						</a-row>

					</a-card>
				</a-col>

			</a-row>


			<div v-if="loadingDetails">

				<a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle" class="" style="min-height: 60vh">

					<a-col :span="24" :md="12" :lg="12" :xl="12" class="text-center justify-items-center">

						<a-spin class="text-primary" size="large"/>

					</a-col>

				</a-row>
			</div>
		</a-card>


        <!-- FORM MODAL -->
		<a-modal v-model="choice.showModal" title="" :footer="null">
			<h4 class="text-center">{{  choice.isCreate ? 'Add Choice' : 'Edit Choice'}}</h4>

			<a-form-model
                class="choice-form"
                @submit="handleSubmitChoice"
                :hideRequiredMark="true">

                <a-form-item class="mb-20" label="Question" :colon="false">
                    <quill-editor
                        ref="questionEditor"
                        v-model="choiceContent"
                        :options="editorOption"
                        @change="handleAnswerChange($event)"
                        required
                    />
                </a-form-item>
                
                <a-form-item class="mb-10 mt-20" label="" :colon="false">
                    Correct &nbsp;&nbsp;<a-switch class="mt-0 pt-0" v-model="choice.correct" />
                </a-form-item>

                <a-form-item>
                    <a-button type="primary" id="btn" html-type="submit" class="login-form-button text-white">
                        {{ choice.isCreate ? 'Add' : 'Edit' }}
                    </a-button>
                </a-form-item>

            </a-form-model>
			
		</a-modal>

	</div>
</template>

<script>
	import { DoubleBounce } from 'vue-loading-spinner'
	import WidgetCounter from '../../../components/Widgets/WidgetCounter';
	import Quill from 'quill'
	import { quillEditor } from "vue-quill-editor";
	import "quill/dist/quill.core.css";
	import "quill/dist/quill.snow.css";
	import "quill/dist/quill.bubble.css";
	// import "katex/dist/katex.min.css";
	// import katex from 'katex';
	// import "katex/dist/katex.js";
	import ImageUploader from "quill-image-uploader";
	// import { ImageActions } from "./quill-image-actions";
    // import { ImageFormats } from "./quill-image-formats";
    import { notification } from 'ant-design-vue';
	import BlotFormatter from 'quill-blot-formatter'
	// import '@vueup/vue-quill/dist/vue-quill.snow.css'
	// import AlignAction from 'quill-blot-formatter/dist/actions/align/AlignAction';
	// import DeleteAction from 'quill-blot-formatter/dist/actions/DeleteAction';
	// import ImageSpec from 'quill-blot-formatter/dist/specs/ImageSpec';
    var QuillDeltaToHtmlConverter = require('quill-delta-to-html').QuillDeltaToHtmlConverter;
	Quill.register("modules/imageUploader", ImageUploader);
	Quill.register('modules/blotFormatter', BlotFormatter);
	// Quill.register("modules/imageActions", ImageActions);
    // Quill.register("modules/imageFormats", ImageFormats);
	// Quill.register('modules/imageResize', ImageResize)
	
	// var quill = new Quill('#editor', {
	// 	modules: {
	// 		formula: true,
	// 		toolbar: [['formula']]
	// 	},
	// 	theme: 'snow'
	// });


	// var enableMathQuillFormulaAuthoring = mathquill4quill();
	// enableMathQuillFormulaAuthoring(quill);

	// enableMathQuillFormulaAuthoring(quill, {
	// 	operators: [["\\sqrt[n]{x}", "\\nthroot"], ["\\frac{x}{y}","\\frac"]]
	// });

// 	class CustomImageSpec extends ImageSpec {
//     getActions() {
//         return [AlignAction, DeleteAction];
//     }
// }

	export default ({
		
		components: {
			DoubleBounce,
			WidgetCounter,
			quillEditor,
		},

        props: ['classDetails', 'isCreator'],
		data() {
			return {
				loadingDetails: false,
				loadingData: false,
                questions: [],
				form: this.$form.createForm(this, { name: 'quiz_question' }),
				question: {
					quiz: this.$route.params.quizUuid,
					content: null,
					position: null,
					choices: [],
					isCreate: false,
					showModal: false,
				},

				choice: {
					index: null,
					content: null,
					correct: false,
					isCreate: true,
					showModal: false,
				},

				questionContent: '',
                choiceContent: '',

				editorOption: {
					modules: {
						formula: true,
						blotFormatter: {},
						toolbar: [
							['bold', 'italic', 'underline', 'strike'],        // toggled buttons
							['blockquote', 'code-block'],

							[{ 'header': 1 }, { 'header': 2 }],               // custom button values
							[{ 'list': 'ordered'}, { 'list': 'bullet' }],
							[{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
							[{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
							[{ 'direction': 'rtl' }],                         // text direction
							// [{ 'size': ['small', false, 'large', 'huge'] }],  // custom dropdown
							// [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
							// [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
							// [{ 'font': [] }],
							// [{ 'align': [] }],
							["image"],
							["formula"],
							['clean'],   
						],
						// ImageResize: {
						// 	modules: [ 'Resize', 'DisplaySize', 'Toolbar' ]
						// },
						imageUploader: {
							upload: (file) => {
								
								return new Promise((resolve, reject) => {

									var formData = new FormData()
    								formData.append('random', file)

									let token = localStorage.getItem("user_token")

									let url = `${this.$BACKEND_URL}/avatars/single-random-store`;

									const config = {
										headers: {
											'Content-Type': 'multipart/form-data',
											'Authorization': `Bearer ${token}`,
										},
									};

									this.$AXIOS.post(url, formData, config).then((response) => {
										// console.log(response.data)
										resolve(response.data.url)
									}).catch(async(error) => {
										// console.log(error)
										reject("Upload failed")
									});
								});
								
							},
						},
						
					}
				}
			}
				
		},
		computed: {
			editor() {
				return this.$refs.questionEditor.quill
			}
		},
		// mounted() {
  			

			

			// const mathquill4quill = window;
			// var enableMathQuillFormulaAuthoring = mathquill4quill();
			// enableMathQuillFormulaAuthoring(this.$refs.questionEditor, {
			// 	operators: [["\\sqrt[n]{x}", "\\nthroot"], ["\\frac{x}{y}","\\frac"]]
			// });
			// console.log('this is current quill instance object', this.editor)
		// },
		async mounted () {
			window.katex = katex;

			let breadcrumbs = [
				{ title: 'Classes', url: '/institutions/classes', isActive: false },
                { title: 'Classroom', url: `/institutions/my-classes/view/${this.$route.params.uuid}`, isActive: false },
                { title: 'View Topic', url: `/institutions/my-classes/view/${this.$route.params.uuid}/topic/${this.$route.params.topicUuid}`, isActive: false },
				{ title: 'Quiz', url: `/institutions/my-classes/view/${this.$route.params.uuid}/topic/${this.$route.params.topicUuid}/quiz/${this.$route.params.quizUuid}`, isActive: false },
				{ title: 'Edit Question', url: ``, isActive: true },
			];
			
			await this.$root.$refs.Breadcrumbs.initializeBreadCrumbs(breadcrumbs)
		},
		created() {
			this.question.uuid = this.$route.params.questionUuid
			this.getQuizQuestionDetails()
		},
		
		methods: {

			handleQuestionChange({ quill, html, text }) {
				// console.log('editor change!', quill)
				this.questionContent = html
				this.question.content = JSON.stringify(quill.editor.delta) 
			},

            handleAnswerChange({ quill, html, text }) {
				// console.log('editor change!', quill)
				this.choiceContent = html
				this.choice.content = quill
			},

			handleEditAnswer(index, choice) {
				// console.log('editor change!', quill)
				this.choiceContent = this.quillToHtml(choice.content)
				this.choice.uuid = choice.uuid
				this.choice.index = index
				this.choice.content = JSON.parse(choice.content)
				this.choice.correct = choice.correct
				this.choice.isCreate = false;
				this.choice.showModal = true
			},

            toggleChoiceDialog() {
                this.choice.showModal = !this.choice.showModal;
            },

            getLabel(index) {
                if(index == '0') {
                    return 'a';
                }else if(index == '1') {
                    return 'b';
                }else if(index == '2') {
                    return 'c';
                }else if(index == '3') {
                    return 'd';
                }else if(index == '4') {
                    return 'e';
                }else if(index == '5') {
                    return 'f';
                }else if(index == '6') {
                    return 'g';
                }else if(index == '7') {
                    return 'h';
                }else {
                    return 'a';
                }
            },


            handleSubmitChoice(event) {
                event.preventDefault()

				if(this.choice.isCreate) {
					const choice = {
						uuid: this.choice.uuid,
						content: JSON.stringify(this.choice.content.editor.delta),
						correct: this.choice.correct
					}

					this.question.choices.push(choice);
					this.choice.content = null;
					this.choice.correct = false;
					this.choiceContent = '';
					this.choice.showModal = false;
				}else{
					const choice = {
						uuid: this.choice.uuid,
						content: JSON.stringify(this.choice.content.editor.delta),
						correct: this.choice.correct,
					}

					this.question.choices[this.choice.index] = choice;
					this.choice.index = null;
					this.choice.content = null;
					this.choice.correct = false;
					this.choiceContent = '';
					this.choice.isCreate = true;
					this.choice.showModal = false;
				}
			},


			removeChoice(index) {

				this.question.choices.splice(index, 1);

			},

			
			quillToHtml(deltaOps) {
                let jsonDeltaOps = JSON.parse(deltaOps)
				const delta = jsonDeltaOps.ops

				var cfg = {};

				var converter = new QuillDeltaToHtmlConverter(delta, cfg);

				var html = converter.convert();

                return html

                // console.log(html)

				// return  {
				// 	__html: DOMPurify.sanitize(html)
				// }
			},


			addOps(value) {
				let defaultStarter = '{\"ops\":';
				let defaultFinisher = '}';

				let formattedValue = `${defaultStarter}${value}${defaultFinisher}`;

				return formattedValue;

			},


			removeOps(value) {

				let trimmedValue = ''

				if(value != null && value.includes('{\"ops\":')) {

					let value2 = value.replace('{\"ops\":', '')

					trimmedValue = value2.substring(0, value2.length - 1);
					
				}else{
					trimmedValue = value;
				}

				return trimmedValue
			},
			

			launchModal() {
				this.question.showModal = true;
			},



			async getQuizQuestionDetails() {

				this.loadingDetails = true;

				let token = await localStorage.getItem("user_token")

                let url = `${this.$BACKEND_URL}/quizzes/questions?uuid=${this.$route.params.questionUuid}`;

                this.$AXIOS.get(url).then(async(response) => {
                    if (response.status == 200)  {

						this.question.uuid = response.data.data.uuid;
						this.questionContent = this.quillToHtml(this.addOps(response.data.data.content))
						this.question.content = this.addOps(response.data.data.content);
						this.question.choices = response.data.data.choices.map(choice =>  { 
							let { content, ...choiceDetails } = choice;
							let newChoiceContent = this.addOps(content)
							choiceDetails.content = newChoiceContent;
							return choiceDetails;
						});
						this.question.position = response.data.data.position;

                    }
					
					this.loadingDetails = false;
                }).catch(async(error) => {
					
					this.loadingDetails = false;

                    if(error.response && error.response.status == 401) {
						await localStorage.setItem("user_token", null);
						await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

                        this.$router.push(`/sign-in`);
                    }
                });
            },

		

			async handleSubmit(e) {

				e.preventDefault();

				this.form.validateFields(async (err, values) => {
                    
					
					if ( !err ) {

							this.loadingData = true;

							let question = this.question;

							question.content = this.removeOps(this.question.content);

							question.choices = this.question.choices.map(choice =>  { 
								let { content, ...choiceDetails } = choice;
								let newChoiceContent = this.removeOps(content)
								choiceDetails.content = newChoiceContent;
								choiceDetails.uuid = choice.uuid != null && choice.uuid.length > 20 ? choice.uuid : this.$UUID.v4(); 
								return choiceDetails;
							});

							let url = `${this.$BACKEND_URL}/quizzes/questions/${this.$route.params.questionUuid}`;

							this.$AXIOS.patch(url, question).then(async(response) => {
								
								this.question.uuid = null,
								this.question.content = null,
								this.question.position = null,
								this.question.choices = [],
								this.question.isCreate = true,
								this.question.showModal = false,

								this.form.resetFields()

                                this.notify('Details were updated successfully', 'success')

								
								this.$router.push(`/my-classes/view/${this.$route.params.uuid}/topic/${this.$route.params.topicUuid}/quiz/${this.$route.params.quizUuid}`);

								// this.loadingData = false;

							}).catch(async(error) => {
								
								// this.loadingData = false;
                                this.notify(error.response.data.message ?? 'Connection errror', 'error')

								if(error.response && error.response.status == 401) {
									await localStorage.setItem("user_token", null);
									await localStorage.setItem("user_details", null)
						await localStorage.setItem("institution_details", null)

									this.$router.push(`/sign-in`);
								}
							});

					
					}
				})
			},


            notify(message, type) {
                notification[type]({
                    message: message,
                    description: '',
                    placement: 'topRight',
                    duration: 3,
                });
                
            },

		}
	})

</script>

<style scoped>

.double-bounce1[data-v-33aee302], .double-bounce2[data-v-33aee302] {
	background-color: #734EBF !important;
	height: 70px;
	width: 70px;
}

a:hover {
	color: inherit !important;
}

#btn, #btn:hover {
	background-color: #734EBF !important;
	color: white !important;
}

.ant-switch-checked {
    background-color: #734EBF !important;
}

#startingTime {
    padding-bottom: 0%;
	margin-bottom: 0%;
	height: 17px;
}

#deleteBtn, #deleteBtn:hover { 
	background-color: inherit !important;
	border: none;
	color: #F5222D;
}


#editBtn, #editBtn:hover, #editBtn:focus { 
	background-color: white !important;
	border: none !important;
	color: #734EBF;
}

p, #deltaValue, #deltaValue > p {
    display: inline-block !important;
}
/* .ant-tabs-nav:hover, .ant-tabs-tab-active {
	color: #734EBF !important;
	border-bottom: 1px solid#734EBF;
} */
</style>